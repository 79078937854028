/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

import { API, graphqlOperation } from '@aws-amplify/api'
import type { Cart } from '@markato-shared/common-types'
import jwtDecode from 'jwt-decode'

import { getUserType } from 'features/login/use-login'

import { useTranslate } from 'hooks'
import { useIsLoggedIn } from 'hooks/use-is-logged-in'

import {
  AccessToken,
  deleteAccessToken,
  getAccessToken,
  getCookie,
  getNewToken,
  setAccessToken,
} from 'services/http-client'
import { GetUserStoreQuery, UpdateUserStoreInput, UserStore } from 'src/API'
import { getUserStore } from 'src/graphql/queries'
import { onUpdateUserStore } from 'src/graphql/subscriptions'
import createSafeContext from 'utils/context'
import { fireTrackingEvent } from 'utils/tracking'

import { ROUTES } from 'constants/routes'

import { useEnvironmentConfig } from './useEnvironmentConfig'

type UserInfo = {
  firstName: string
  lastName: string
  email: string
  country: string
  countryId: string
  productPreferences: string[]
  currency: string
  cart?: Cart
  brandName?: string
  currencySign?: string
  hasPendingPaymentTermsApplication?: boolean
  lengthUnit: string
  weightUnit: string
  autoBuyNowPayLaterAmount: string
  buyNowPayLaterDelay: string
}
export type DynamoDbUser = {
  cognitoUserId: string
  info: UserInfo
}
export type DynamoDbUserContextType = {
  dynamoDbUser?: DynamoDbUser
  getUserFromDynamo: (cbFn?: ((userInfo?: UserInfo, cognitoId?: string) => void) | undefined) => void
  unsetDynamoDbUser: () => void
}

export type UpdateUserStoreSubscribeType = {
  data: {
    onUpdateUserStore: UpdateUserStoreInput
  }
}

const DynamoDbUserContext = createSafeContext<DynamoDbUserContextType>()

export const useDynamoDbUserContext: () => DynamoDbUserContextType = DynamoDbUserContext.hook

const DynamoDbUserContextProvider = ({ children }: { children: ReactNode }) => {
  const { accessToken } = useIsLoggedIn()

  const { awsConfig } = useEnvironmentConfig()

  API.configure(awsConfig)
  const [dynamoDbUser, setDynamoDbUser] = useState<DynamoDbUser>()

  const { langSubPath, country, defaultRedirectSubPath } = useTranslate('')

  const unsetDynamoDbUser = () => {
    setDynamoDbUser(undefined)
  }

  const handlError = () => {
    deleteAccessToken()
    unsetDynamoDbUser()
    window.location.reload()
  }

  const getUserStoreData = useCallback(async (cognitoUserId: string, userType = '') => {
    try {
      const fetchUserStore = async () => {
        const response = (await API.graphql({
          query: getUserStore,
          variables: { cognitoUserId },
        })) as { data: GetUserStoreQuery }
        // getUserStore is null for onboarding users
        return response.data.getUserStore as UserStore | null
      }

      let userStore = await fetchUserStore()

      // Fix obj got deleted by TTL
      if (!userStore && (userType === 'Retailer' || userType === 'Brand')) {
        const {
          data: { accessToken, expiresIn },
        } = await getNewToken()
        setAccessToken(accessToken, expiresIn)
        userStore = await fetchUserStore()
      }

      return userStore
    } catch (error) {
      return Promise.reject(error)
    }
  }, [])

  const countryGuardAfterLogin = (
    langSubPath: string,
    country: string,
    defaultRedirectSubPath: Record<string, string>,
    userCountry: string,
    userType: string
  ) => {
    if (
      country !== userCountry &&
      (userType === 'Retailer' || userType === 'Guest') &&
      defaultRedirectSubPath[userCountry]
    ) {
      // Fix form country mismatch current url
      if (!location.href.includes(ROUTES.RETAILER_ONBOARDING)) {
        // Don't use router.push as the lang switcher will not update
        setTimeout(() => {
          location.href = location.href.replace(langSubPath, defaultRedirectSubPath[userCountry])
        }, 500)
      }
    }
  }

  const parseGraphqlResponse = (
    userType: string | undefined,
    username: string | undefined,
    source: string,
    userStore?: UserStore
  ) => {
    // response.DOC is null for onboarding users with userType Guest
    if (userStore?.DOC) {
      const userInfoJson = JSON.parse(userStore.DOC) as UserInfo

      countryGuardAfterLogin(langSubPath, country, defaultRedirectSubPath, userInfoJson.country, userType ?? '')

      setDynamoDbUser({
        cognitoUserId: userStore.cognitoUserId,
        info: userInfoJson,
      })
    } else {
      if (userType !== 'Guest') {
        if (userStore) {
          fireTrackingEvent('failure', {
            name: 'failure_dynamo_db_user',
            errorCode: ['getUserStore is defined but response.DOC is null', source],
            nativeEvent: { userType, username, response: userStore },
          })
        } else {
          fireTrackingEvent('failure', {
            name: 'failure_dynamo_db_user',
            errorCode: ['getUserStore is null', source],
            nativeEvent: { userType, username, response: userStore },
          })
        }

        handlError()
      }
    }
  }

  const getUserFromDynamo = useCallback((cbFn?: (arg0: UserInfo, arg1: string) => void) => {
    const accessToken = getAccessToken()
    const cookieImpersonate = getCookie('impersonated-token')

    if (accessToken) {
      const decodedToken = jwtDecode(accessToken) as AccessToken
      const username = cookieImpersonate
        ? (jwtDecode(cookieImpersonate) as AccessToken).preferred_username
        : decodedToken.sub
      const userType = getUserType(accessToken)

      // Fetech user data from DynamoDB
      void getUserStoreData(username, userType)
        .then((userStore) => {
          userStore && parseGraphqlResponse(userType, username, 'then', userStore)
          userStore && cbFn?.(JSON.parse(userStore.DOC ?? '') as UserInfo, username)

          // Subscribe to user data changes
          ;(
            API.graphql(
              graphqlOperation(onUpdateUserStore, {
                cognitoUserId: username,
              })
            ) as any
          ).subscribe({
            next: ({ value }: { value: UpdateUserStoreSubscribeType }) => {
              parseGraphqlResponse(userType, username, 'subscribe', value.data.onUpdateUserStore as UserStore)
            },
          })
        })
        .catch((error) => {
          fireTrackingEvent('failure', {
            name: 'failure_dynamo_db_user',
            errorCode: ['graphql error'],
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            nativeEvent: { error, username },
          })
          handlError()
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (accessToken && !dynamoDbUser) {
      getUserFromDynamo()
    }
  }, [accessToken, dynamoDbUser, getUserFromDynamo])

  const value = useMemo<DynamoDbUserContextType>(
    () => ({ dynamoDbUser, getUserFromDynamo, unsetDynamoDbUser }),
    [dynamoDbUser, getUserFromDynamo]
  )

  return <DynamoDbUserContext.Provider value={value}>{children}</DynamoDbUserContext.Provider>
}

export default DynamoDbUserContextProvider
