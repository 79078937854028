import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import cn from 'classnames'
import { isEmpty } from 'lodash'

import Button from 'components/button'
import { RegularHeart, RegularHome, RegularShoppingBag } from 'components/icon/regular'
import ImageComponent from 'components/image'
import Link from 'components/link'
import Logo from 'components/logo'
import { Autocomplete } from 'components/molecules/autocomplete'
import { Notification } from 'components/notification'
import { MiniCart } from 'features/cart/mini-cart'
import { getUserType } from 'features/login/use-login'

import { useDynamoDbUserContext } from 'contexts/DynamoDbUserContext'
import { useTranslate } from 'hooks'
import { useZendesk } from 'hooks/use-zendesk'

import { ROUTES } from 'constants/routes'

import scss from '../../../../styles/javascript.export.module.scss'
import LangSwitcher from '../lang-switcher'
import css from './app-header.module.scss'
import NavMenuMobile from './nav-menu-mobile'
import UserMenu from './user-menu'

export type HeaderProps = {
  displayTopBanner: boolean
  onLoginClick?: () => void
  onSignUpClick?: () => void
  accessToken: string | null
  showNavbar?: boolean
}

const minYOffsetForStickyHeader = 150
const minYOffsetForNonStickyHeader = 75

export const AppHeader = ({ onLoginClick, onSignUpClick, accessToken }: HeaderProps) => {
  const { t } = useTranslate('common')

  const { dynamoDbUser } = useDynamoDbUserContext()
  const router = useRouter()
  const { q: text } = router.query
  const [isCartOpened, setIsCartOpened] = useState(false)

  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false)
  const [isSticky, setIsSticky] = useState(false)
  const userType = accessToken ? getUserType(accessToken) : 'Guest'

  useEffect(() => {
    setIsMobileMenuOpened(false)
  }, [text, router.asPath])

  const search = <Autocomplete />

  useEffect(() => {
    const onScroll = () =>
      setIsSticky(window.pageYOffset > (isSticky ? minYOffsetForNonStickyHeader : minYOffsetForStickyHeader))

    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [isSticky])

  const { updateSettings, prefill } = useZendesk()

  useEffect(() => {
    if (dynamoDbUser && userType === 'Retailer') {
      prefill({
        name: { value: `${dynamoDbUser.info.firstName} ${dynamoDbUser.info.lastName}` },
        email: { value: dynamoDbUser.info.email },
      })
      updateSettings({
        contactForm: {
          ticketForms: [{ id: 10419228356495 }],
        },
      })
    }
  }, [dynamoDbUser, userType, prefill, updateSettings])

  return (
    <div className={[css.outter, isSticky ? css.isSticky : css.notSticky].join(' ')}>
      <div className={css.container}>
        <div className={css.logo} style={{ width: '330px' }}>
          <Link href="/">
            <Logo width={130} height={35} noEmbed variant="primary" />
          </Link>
        </div>
        <div className={css.searchWrapper}>{search}</div>
        <div className={css.rightSideWrapper}>
          {accessToken ? (
            <>
              <LangSwitcher />
              {dynamoDbUser && userType === 'Brand' ? (
                <Button
                  icon={<RegularHome color={scss.systemColourBlack} />}
                  className={css.links}
                  onClick={() => void router.push(ROUTES.PORTAL)}
                />
              ) : null}
              {userType === 'Guest' ? (
                <Button
                  icon={<RegularHome color={scss.systemColourBlack} />}
                  className={css.links}
                  onClick={() => void router.push(ROUTES.RETAILER_ONBOARDING)}
                />
              ) : null}
              {dynamoDbUser && userType === 'Retailer' ? (
                <>
                  <UserMenu user={dynamoDbUser} />
                  <Button
                    icon={<RegularHeart color={scss.systemColourBlack} />}
                    className={css.links}
                    onClick={() => void router.push(ROUTES.RETAILER_FAVOURITES)}
                  />
                  <div
                    className={css.cartButton}
                    onClick={() => {
                      if (!isEmpty(dynamoDbUser.info.cart?.brands)) {
                        setIsCartOpened((prevVal) => !prevVal)
                      } else {
                        void router.push('/retailer/cart')
                      }
                    }}
                  >
                    <Button
                      data-test-id="open-mini-cart-button"
                      icon={<RegularShoppingBag color={scss.systemColourBlack} />}
                      className={css.links}
                    />
                    {!!dynamoDbUser.info.cart && dynamoDbUser.info.cart.brands.length > 0 && (
                      <div className={css.notification}>
                        <Notification
                          variant="secondary"
                          numberOfNotifications={dynamoDbUser.info.cart.brands.reduce(
                            (acc, brand) => acc + brand.items.length,
                            0
                          )}
                        />
                      </div>
                    )}
                  </div>
                </>
              ) : null}
            </>
          ) : (
            <>
              <LangSwitcher />
              <Button
                data-test-id="open-retailer-signup-button"
                label={t('header.signup')}
                size="small"
                className={cn([css.button, css.signup, css.borderlessButton])}
                onClick={onSignUpClick}
                trackName="cta_home_nav_signup"
              />
              <Button
                data-test-id="choose-to-login-modal-pop-up-button"
                label={t('header.login')}
                style="tertiary"
                size="small"
                className={[css.button, css.primary].join(' ')}
                onClick={onLoginClick}
                trackName="cta_home_nav_login"
              />
            </>
          )}
        </div>
        <div className={css.mobileMenuWrapper}>
          {!!accessToken && (
            <div
              className={[
                css.menuButton,
                css.cart,
                dynamoDbUser?.info.cart && dynamoDbUser.info.cart.brands.length > 0 ? css.hasItem : css.empty,
              ].join(' ')}
              onClick={() => {
                if (dynamoDbUser && !isEmpty(dynamoDbUser.info.cart?.brands)) {
                  setIsCartOpened((prevVal) => !prevVal)
                } else {
                  void router.push('/retailer/cart')
                }
              }}
            >
              <Button
                style="tertiary"
                icon={<RegularShoppingBag color={scss.systemColourBlack} />}
                className={css.links}
              />
              {!!dynamoDbUser?.info.cart && dynamoDbUser.info.cart.brands.length > 0 && (
                <div className={css.notification}>
                  <Notification
                    variant="secondary"
                    numberOfNotifications={dynamoDbUser.info.cart.brands.reduce(
                      (acc, brand) => acc + brand.items.length,
                      0
                    )}
                  />
                </div>
              )}
            </div>
          )}

          <div className={css.menuButton}>
            <ImageComponent
              src="/images/icons/mobile-menu.svg"
              alt={''}
              width={24}
              height={24}
              onClick={() => setIsMobileMenuOpened(true)}
            />
          </div>
        </div>
      </div>
      {!!isMobileMenuOpened && (
        <NavMenuMobile
          onCloseMenu={() => setIsMobileMenuOpened(false)}
          onLoginClick={onLoginClick}
          onSignUpClick={onSignUpClick}
        />
      )}

      {!!dynamoDbUser?.info.cart?.brands && (
        <MiniCart
          isOpened={isCartOpened}
          brands={dynamoDbUser.info.cart.brands}
          financial={dynamoDbUser.info.cart.financial}
          currency={dynamoDbUser.info.cart.retailer?.currency ?? 'EUR'}
          closeCart={() => setIsCartOpened(false)}
        />
      )}
    </div>
  )
}
